<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="12">
            <div class="iq-edit-list-data">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">Editar Información Personal</h4>
                </template>
                <template v-slot:body>
                  <b-row align-v="center">
                    <b-form-group
                      class="col-md-6"
                      label="Nombres"
                      label-for="firstname"
                    >
                      <ValidationProvider
                        name="Nombres"
                        rules="required|min:4|max:30|alpha_spaces"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.firstname"
                          type="text"
                          placeholder="Nombres"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Apellidos"
                      label-for="lastname"
                    >
                      <ValidationProvider
                        name="Apellidos"
                        rules="required|min:4|max:30|alpha_spaces"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.lastname"
                          type="text"
                          placeholder="Apellidos"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group class="col-md-6" label="RUT" label-for="rut">
                      <ValidationProvider
                        name="RUT"
                        rules="required|min:7"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.rut"
                          :value="validate(user.rut)"
                          type="text"
                          placeholder="RUT"
                          :class="
                            errors.length > 0 || validateRut == false
                              ? ' is-invalid'
                              : ''
                          "
                          v-mask="['########-X', '#######-X']"
                        ></b-form-input>
                        <small class="text-danger" v-if="validateRut == false"
                          >RUT invalido</small
                        >
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Teléfono"
                      label-for="phone"
                    >
                      <ValidationProvider
                        name="Telefono"
                        rules=""
                        v-slot="{ errors }"
                      >
                        <vue-tel-input
                        :inputOptions="{ placeholder: 'Telefono'}"
                        v-model="user.phone"
                        :class="
                            errors.length > 0
                              ? ' is-invalid'
                              : ''
                          "
                        ></vue-tel-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label-for="comunas"
                      label="Comuna"
                    >
                      <v-select
                        placeholder="Seleccionar.."
                        v-model="user.comuna"
                        label="name"
                        :options="comunas"
                      >
                      </v-select>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Correo Electrónico"
                      label-for="email"
                    >
                      <ValidationProvider
                        name="Correo Electrónico"
                        rules="required||email"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.email"
                          type="text"
                          placeholder="Correo Electrónico"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-12"
                      label="Dirección"
                      label-for="address"
                    >
                      <ValidationProvider
                        name="Dirección"
                        rules="required||min:10"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.address"
                          type="text"
                          placeholder="Dirección"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Contraseña"
                      label-for="Contraseña"
                    >
                      <ValidationProvider
                        name="Contraseña"
                        rules="min:8|confirmed:password_confirmation"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.password"
                          type="password"
                          placeholder="Contraseña"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Confirmar contraseña"
                      label-for="Confirmar contraseña"
                    >
                      <ValidationProvider
                        vid="password_confirmation"
                        name="Confirmar contraseña"
                        rules="min:8"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.password_confirmation"
                          type="password"
                          placeholder="Confirmar contraseña"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </b-row>

                  <b-button type="submit" variant="primary" class="mr-2"
                    >Guardar Cambios</b-button
                  >
                  <b-button to="/" variant="none" class="iq-bg-danger"
                    >Cancelar</b-button
                  >
                </template>
              </iq-card>
            </div>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import verificador from 'verificador-rut'
export default {
  name: 'ProfileEdit',
  async mounted () {
    core.index()
    this.$store.commit("SET_BREADCRUMB", this.items);
    this.$store.commit('run')
    const {
      firstname,
      lastname,
      rut,
      phone,
      email,
      address,
      comuna
    } = await this.$store.dispatch('profile/fetchUserProfile')
    const comunas = await this.$store.dispatch('tool/fetchComunas')
    this.user = { firstname, lastname, rut, phone, email, address, comuna }
    this.comunas = comunas.map((item) => ({ id: item.id, name: item.name }))
    this.$store.commit('stop')
  },
  data () {
    return {
      comunas: [],
      user: {
        firstname: '',
        lastname: '',
        rut: '',
        phone: '',
        email: '',
        address: '',
        comuna: '',
        password: null,
        password_confirmation: null,
      },
      validateRut: true,
      items: [
        {
          html: 'Inicio',
          to: '/'
        },
        {
          text: 'Editar Perfil',
          active: true
        }
      ]
    }
  },
  methods: {
    validate (rut) {
      if (verificador(rut)) {
        this.validateRut = true
      } else {
        this.validateRut = false
      }
    },
    async onSubmit () {
      try {
        const payload = {
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          rut: this.user.rut,
          phone: this.user.phone,
          email: this.user.email,
          address: this.user.address,
          comuna: this.user.comuna.id,
          password: this.user.password,
          password_confirmation: this.user.password_confirmation
        }
        this.$store.commit('run')
        await this.$store.dispatch(
          'profile/updateUserProfile',
          payload
        )
        this.$store.commit('stop')
        core.showSnackbar('success', 'Perfil actualizado')
        this.$router.replace('/profile/user')
      } catch (error) {
        this.$store.commit('stop')
        const errors = error.response.data.errors
        for (const key in errors) {
          core.showSnackbar('error', errors[key][0])
          return false
        }
      }
    }
  }
}
</script>
<style>
.vs__clear {
  margin-right: 5px !important;
  margin-bottom: 3px !important;
}
</style>
